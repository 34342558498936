import { Controller } from "stimulus";

const name_field = document.getElementById("document_name");
const blob_size_field = document.getElementById("document_blob_size");
const blob_name_field = document.getElementById("document_blob_name");
const blob_url_field = document.getElementById("document_blob_url");
const blob_file_field = document.getElementById("document_blob_file");

const controls = document.getElementById("controls");
const spinner = document.getElementById("spinner");

const upload_error_modal = document.getElementById("upload_error_modal");

export default class extends Controller {
  async handle_submit(event){
    event.preventDefault();
    try {
      showSpinner();
      const { sasKey, blobUrl } = await fetchSasKey();

      const file = getFileFromInput();
      await this.set_hidden_values(file, blobUrl);
      await uploadFileToAzure(file, sasKey);

      this.submit();
    }catch (error) {
      upload_error_modal.style.display = "block";
      hideSpinner();
      console.error(error);
    }
  }

  set_name(event){
    const file = event.target.files[0]
    if (name_field.value != "") return;

    const {name} = splitFileName(file.name)
    name_field.value = name
  }

  async set_hidden_values(file, blobUrl){
    if (!file) return;

    blob_size_field.value = file.size;
    blob_name_field.value = await getFileName(file);
    blob_url_field.value = await createURL(blobUrl, file);
  }

  submit(){
    blob_file_field.disabled = true
    this.element.querySelector("form").submit()
    blob_file_field.disabled = false
  }
}

async function fetchSasKey() {
  const response = await fetch('/evaluado/documentos/request_sas', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

async function uploadFileToAzure(file, sasKey) {
  if (!file) return;

  const uploadUrl = `${blob_url_field.value}?${sasKey}`;
  // Upload the file to Azure Blob Storage
  const uploadResponse = await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': file.type
    },
    body: file
  });

  if (!uploadResponse.ok) {
    throw new Error(`HTTP error during upload! status: ${uploadResponse.status}`);
  }

  console.log('File uploaded successfully');
  }

  function getFileFromInput() {
    const file = document.querySelector('#document_blob_file').files[0];
    return file;
  }

  function splitFileName(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return { name: fileName, extension: '' };
    }

    const name = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex + 1);

    return { name, extension };
  }

  async function getFileName(file){
    var {name, extension} = splitFileName(file.name);
    if (name_field.value != "") name = name_field.value.trim(); // si no hay un nombre, se mantiene el original
    const hash = await createFileHash(file)

    return `${name}_${hash}.${extension}`
  }

  function showSpinner(){
    controls.classList.add('d-none');
    spinner.classList.remove('d-none');
  }

  function hideSpinner(){
    controls.classList.remove('d-none');
    spinner.classList.add('d-none');
  }

  async function createURL(blobUrl, file){
    return `${blobUrl}/${encodeURIComponent(await getFileName(file))}`
  }

  async function createFileHash(file) {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex.slice(0, 16); // Truncate to a smaller hash if needed
  }
